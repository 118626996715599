import React from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  display: block;
  width: ${(props) => props.width};
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  max-height: 400px;
  min-width: 100px;
  img {
    object-fit: contain;
    width: 100%;
  }
`;

const Image = ({ width, url, alt, marginBottom }) => {
  return (
    <ImageContainer width={width} marginBottom={marginBottom}>
      <img src={url} alt={alt} />
    </ImageContainer>
  );
};

export default Image;
