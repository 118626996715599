import React from "react";
import styled from "styled-components";

import Image from "./Image";

const ContainerBackGround = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url("./Images/background.jpg");
  background-position: 0 -150px;
  background-size: 100%;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1200px) {
    background-position: 0 -100px;
  }

  @media only screen and (max-width: 968px) {
    background-position: 0 -30px;
  }

  @media only screen and (max-width: 640px) {
    background-position: top center;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 80px;
  left: 25%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  max-width: 700px;
  h1 {
    margin-top: 40px;
    font-size: 50px;
  }
  h3 {
    margin-top: 30px;
    font-size: 20px;
  }
  a {
    text-decoration: none;
    font-size: 20px;
    color: #000;
    font-weight: 600;
    span {
      font-family: "sans-serif";
    }
  }

  p {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
  }

  @media only screen and (max-width: 1200px) {
    top: 70px;
    h1 {
      font-size: 40px;
    }
    h3 {
      margin-bottom: -30px;
    }
  }

  @media only screen and (max-width: 968px) {
    top: 60px;
    left: 12.5%;
    width: 75%;
    h1 {
      margin-top: 40px;
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 640px) {
    top: 50px;
    h1 {
      margin-top: 20px;
      font-size: 30px;
    }
    h3 {
      margin-top: 20px;
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
    p {
      margin-top: 5px;
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 400px) {
    top: 30px;
  }
  @media only screen and (max-height: 700px) {
    top: 10px !important;
  }
`;

const Main = () => {
  return (
    <ContainerBackGround>
      <ContentContainer>
        <Image
          width='175px'
          alt='hype-enterprise'
          url='./Images/hypeenterprise.jpg'
        />
        <h1>Yenileniyoruz</h1>
        <h3>
          Sitemiz yeniden hizmet verene kadar bize markalarımızın websiteleri
          üzerinden ve iletişim kanallarımızdan ulaşabilirsiniz.
        </h3>
        <a href='https://www.hawksbill.co/'>
          <Image
            width='250px'
            alt='hawksbill'
            url='./Images/hawksbill.png'
            marginBottom='-50px'
          />
        </a>
        <a href='http://www.urbanpieces.co/' target='_blank' rel='noreferrer'>
          <Image
            width='250px'
            alt='urbanpieces'
            url='./Images/urbanpieces.png'
            marginBottom='30px'
          />
        </a>

        <a href='mailto:info@hypeenterprise.com?subject=Hypeenterprise Hakkinda'>
          info
          <span>@</span>
          hypeenterprise.com
        </a>
        <p>
          Bosna Bulvarı Çamlıca Ulusoy Plaza No:25 D:11 Kısıklı / Üsküdar -
          İSTANBUL
        </p>
      </ContentContainer>
    </ContainerBackGround>
  );
};

export default Main;
